<template>
  <svg class="size-20 animate-spin stroke-blue-700">
    <circle
      class="stroke-blue-700 opacity-10"
      r="20"
      fill="none"
      stroke-width="5"
      cx="40"
      cy="40"
    />
    <path fill="none" stroke-width="5" stroke-linecap="round" d="M 60 40 A 20 20 0 0 1 40 60" />
  </svg>
</template>
